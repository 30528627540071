import React from "react";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";

function Home(){
    return(
        <div>
            <h1>Welcome and Happy Shopping</h1>
            <Link to={"/store"}><Button size="medium" color="inherit">Go to Store</Button></Link>
        </div>
    )
}
export default Home;