import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Button from "@material-ui/core/Button";
import {cart, setCart} from "../App";
import Snackbar from "@material-ui/core/Snackbar";
import {Link, useHistory} from "react-router-dom";
import fire from "../Fire";
import {userEmail} from "./Wrapper";


function Cart(){
    const db = fire.firestore();
    const [rows, setRows] = React.useState(cart.map(value => createRow(value.desc,value.qty,value.unit,value.stock)));
    const invoiceSubtotal = subtotal(rows);
    const TAX_RATE = 0.07;
    const invoiceTaxes = TAX_RATE * invoiceSubtotal;
    const invoiceTotal = invoiceTaxes + invoiceSubtotal;
    const [open, setOpen] = React.useState(false);
    const useStyles = makeStyles({
        table: {
            minWidth: 700,
        },
    });
    const classes = useStyles();
    let history = useHistory();
    const tableRows = rows.map((row) => (
            <TableRow key={row.desc}>
                <TableCell>{row.desc}{row.stock===0?' (Out of Stock)':row.stock<=10?' (Low Stock!!!)':''}</TableCell>
                <TableCell align="right">{row.qty}</TableCell>
                <TableCell align="right">{row.unit}</TableCell>
                <TableCell align="right">{ccyFormat(row.price)}</TableCell>
                <TableCell><button onClick={()=>handleDelete(row.desc)}>Delete</button></TableCell>
            </TableRow>
        ));
    const handleDelete = (item)=>{
        setCart(cart.filter(value => value.desc !== item));
        setRows(cart.map(value => createRow(value.desc,value.qty,value.unit,value.stock)));
    };
    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    const handlePurchase = ()=>{
        // eslint-disable-next-line array-callback-return
        cart.map(value => {
            let order = {
                customer: {
                    email: userEmail
                },
                details: {
                    item: value.desc,
                    quantity: Number(value.qty),
                    price: Number(value.unit),
                    total: Number((Number(value.qty)*Number(value.unit)*(1+TAX_RATE)).toFixed(2))
                },
                date: new Date()
            }
            db.collection("orders").add(order).then(() => {

            });
        });
        cart.map(value => db.collection("products").doc(String(value.id)).update({
            stock: value.stock - value.qty
        }));
        setCart([]);
        setRows(cart.map(value => createRow(value.desc,value.qty,value.unit,value.stock)));
        setOpen(true);
        const fiveSecFromNow = new Date().getTime() + 5000;
        const x = setInterval(function (){
            const now = new Date().getTime();
            const timeLeft = fiveSecFromNow - now;

            if (timeLeft < 0){
                clearInterval(x);
                history.goBack();
            }
        }, 1000)
    }

    function ccyFormat(num) {
        return num!==undefined?`${num.toFixed(2)}`:'';
    }

    function priceRow(qty, unit) {
        return qty * unit;
    }

    function createRow(desc, qty, unit, stock) {
        const price = priceRow(qty, unit);
        return { desc, qty, unit, price, stock };
    }

    function subtotal(items) {
        return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
    }

    return(
        <div style={{marginLeft: "auto", marginRight: "auto", maxWidth:"700px"}}>
            <h1>Check Out</h1>
            <Link to={"/store"}><Button size="medium" color="inherit">Go back to Store</Button></Link>
            <div id="table">
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="spanning table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" colSpan={3}>
                                    Details
                                </TableCell>
                                <TableCell align="right">Price</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Desc</TableCell>
                                <TableCell align="right">Qty.</TableCell>
                                <TableCell align="right">Unit</TableCell>
                                <TableCell align="right">Sum</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableRows}
                            <TableRow>
                                <TableCell rowSpan={3} />
                                <TableCell colSpan={2}>Subtotal</TableCell>
                                <TableCell align="right">{ccyFormat(invoiceSubtotal)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Tax</TableCell>
                                <TableCell align="right">{`${(TAX_RATE * 100).toFixed(0)} %`}</TableCell>
                                <TableCell align="right">{ccyFormat(invoiceTaxes)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={2}>Total</TableCell>
                                <TableCell align="right">{ccyFormat(invoiceTotal)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <Button disabled={cart.length === 0} variant="contained" size="large" color="secondary" onClick={()=>handlePurchase()}>Purchase</Button>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleSnackClose}
                    message="Item(s) successfully purchased!"
                />
            </div>
        </div>
    )
}
export default Cart;