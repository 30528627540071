import React from "react";
import {NavLink} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MenuIcon from '@material-ui/icons/Menu';
import { useLocation } from 'react-router-dom'
import Button from "@material-ui/core/Button";
import fire from "../Fire";

function Nav(){
    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },

    }));
    const handleLogout = () => {
        fire.auth().signOut();
    }

    const classes = useStyles();
    const location = useLocation();
    return(
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar variant="dense">
                    <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {location.pathname !== '/'?location.pathname.slice(1).toUpperCase():'NOVINYO STORE'}
                    </Typography>
                    <nav id="nav">
                        <NavLink activeStyle={{backgroundColor:"#4CAF50",color:"white",display:"inline-block",padding: "09px 0"}} to={"/store"}><Button size="small">Store</Button></NavLink> |&nbsp;
                        <NavLink activeStyle={{backgroundColor: "#4CAF50", color: "white",display:"inline-block",padding: "09px 0"}} to={"/cart"}><Button size="small">Cart</Button></NavLink> |&nbsp;
                        <NavLink activeStyle={{backgroundColor: "#4CAF50", color: "white",display:"inline-block",padding: "09px 0"}} to={"/admin"}><Button size="small">Admin</Button></NavLink> |&nbsp;
                        <NavLink activeStyle={{backgroundColor: "#4CAF50", color: "white",display:"inline-block",padding: "09px 0"}} to={"/orders"}><Button size="small">Orders</Button></NavLink> |&nbsp;
                        <NavLink to={"/"}><button className='nav-button' onClick={handleLogout}>Logout</button></NavLink>
                    </nav>
                </Toolbar>
            </AppBar>
        </div>
    )
}

export default Nav;