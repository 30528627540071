import React from "react";
import './App.css';
import Wrapper from "./components/Wrapper";
export var cart = [];
function App() {
  return (
    <div className="App">
      <Wrapper/>
    </div>
  );
}
export function setCart(prop){
    cart = prop
}
export default App;
