import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import fire from "../Fire";

function Orders(){
    const useStyles = makeStyles({
        table: {
            minWidth: 650,
        },
    });

    const classes = useStyles();
    const db = fire.firestore();
    const [orders, setOrders] = React.useState([]);
    const [rows, setRows] = React.useState([]);

    React.useEffect(() => {
        let newElements = [];
        db.collection("orders").get().then(function (snapshot){
            snapshot.forEach(function (doc){
                const object = doc.data();
                let element = {
                    email: object.customer.email,
                    item: object.details.item,
                    price: object.details.price,
                    qty: object.details.quantity,
                    total: object.details.total,
                    date: object.date.toDate(),
                    id: doc.id
                };
                newElements.push(element);
            })
            setOrders(newElements);
            setRows(orders.map(value => createData(value.date, value.id, value.email, value.item, value.price, value.qty, value.total)));
        })
        // eslint-disable-next-line
    }, [db])

    function createData(date, id, email, item, price, qty, total) {
        return { date, id, email, item, price, qty, total };
    }

    return (
        <div style={{marginLeft: "auto", marginRight: "auto", maxWidth:"1200px"}}>
            <h1>Orders Page</h1>
            <div>
                <p>Current Orders</p>
            </div>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Order ID</TableCell>
                            <TableCell align="right">Order date</TableCell>
                            <TableCell align="right">Customer email</TableCell>
                            <TableCell align="right">Item</TableCell>
                            <TableCell align="right">Price ($)</TableCell>
                            <TableCell align="right">Quantity</TableCell>
                            <TableCell align="right">Total ($)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell component="th" scope="row">
                                    {row.id}
                                </TableCell>
                                <TableCell align="right">{row.date.toLocaleDateString()}</TableCell>
                                <TableCell align="right">{row.email}</TableCell>
                                <TableCell align="right">{row.item}</TableCell>
                                <TableCell align="right">{row.price}</TableCell>
                                <TableCell align="right">{row.qty}</TableCell>
                                <TableCell align="right">{row.total}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default Orders;