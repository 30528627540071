import React from "react";
import fire from "../Fire";
import Product from "./Product";

function Store(){
    const [products, setProducts] = React.useState([]);
    const db = fire.firestore();
    React.useEffect(() => {
        let newItems = [];

        db.collection("products").get().then(function (snapshot){
            snapshot.forEach(function (doc){
                const object = doc.data();

                let item = {
                    name: object.name,
                    price: object.price,
                    stock: object.stock,
                    image: object.image,
                    id: doc.id
                };
                newItems.push(item);
            });
            setProducts(newItems);
        });

    }, [db]);

    const productElements = products.map((elt)=>
        <div style={{display:"inline-block", maxWidth:"1050px"}}>
            <Product name={elt.name} price={elt.price} stock={elt.stock} image={elt.image} id={elt.id}/>
        </div>
    )

    return(
        <div>
            <h1>Items</h1>
            {productElements}
        </div>
    )
}
export default Store;