import React from "react";
import Nav from "./Nav";
import {
    BrowserRouter as Router,
    Route,
    Switch
} from "react-router-dom";
import Admin from "./Admin";
import Cart from "./Cart";
import Store from "./Store";
import Home from "./Home";
import fire from "../Fire";
import Login from "./pages/Login";
import Orders from "./Orders";
export var userEmail = "";
export function setUserEmail(prop){
    userEmail = prop;
}

function Wrapper(){
    const [user, setUser] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [emailError, setEmailError] = React.useState("");
    const [passwordError, setPasswordError] = React.useState("");
    const [hasAccount, setHasAccount] = React.useState(false);

    const clearInputs = () => {
        setEmail("");
        setPassword("");
    }

    const clearErrors = () => {
        setEmailError("");
        setPasswordError("");
    }

    const handleLogin = () => {
        clearErrors()
        fire.auth().signInWithEmailAndPassword(email, password).catch(err => {
            switch (err.code) {
                case "auth/invalid-email":
                case "auth/user-disabled":
                case "auth/user-not-found":
                    setEmailError(err.message);
                    break;
                case "auth/wrong-password":
                    setPasswordError(err.message);
                    break;
                default:
                    break;
            }
        });

    }

    const handleSignup = () => {
        clearErrors()
        fire.auth().createUserWithEmailAndPassword(email, password).catch(err => {
            switch (err.code) {
                case "auth/email-already-in-use":
                case "auth/invalid-email":
                    setEmailError(err.message);
                    break;
                case "auth/weak-password":
                    setPasswordError(err.message);
                    break;
                default:
                    break;
            }
        });
    }

    const authListener = () => {
        fire.auth().onAuthStateChanged((user) => {
            if (user) {
                clearInputs()
                setUser(user);
                setUserEmail(user.email)
            } else {
                setUser("");
            }
        });
    }

    React.useEffect(() => {
        authListener();
        // eslint-disable-next-line
    }, [])
    return(
        <>
            {
                user ? (
                    <div style={{marginLeft: "auto", marginRight: "auto"}}>
                        <Router>
                            <Nav/>
                            <Switch>
                                <Route path={"/orders"} component={Orders}/>
                                <Route path={"/admin"} component={Admin}/>
                                <Route path={"/cart"} component={Cart}/>
                                <Route path={"/store"} component={Store}/>
                                <Route path={"/"} exact component={Home}/>
                            </Switch>
                        </Router>
                    </div>
                ) : (
                    <Login
                        email={email}
                        setEmail={setEmail}
                        password={password}
                        setPassword={setPassword}
                        handleLogin={handleLogin}
                        handleSignup={handleSignup}
                        hasAccount={hasAccount}
                        setHasAccount={setHasAccount}
                        emailError={emailError}
                        passwordError={passwordError}
                    />
                )
            }
        </>
    )
}
export default Wrapper;