import React from "react";
import fire from "../Fire";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/styles";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

function Admin(){
    const useStyles = makeStyles(() => ({
        root: {
            flexGrow: 1, width: '100%',
        },
    }));
    const db = fire.firestore();
    const [products, setProducts] = React.useState([]);
    const [submit, setSubmit] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [edit, setEdit] = React.useState(false);
    const [editID, setEditID] = React.useState("");
    const classes = useStyles();
    const [error1, setError1] = React.useState(false);
    const [error2, setError2] = React.useState(false);
    const [error3, setError3] = React.useState(false);
    const [error4, setError4] = React.useState(false);
    const [canSave, setCanSave] = React.useState(true);
    const [name, setName] = React.useState();
    const [image, setImage] = React.useState();
    const [price, setPrice] = React.useState();
    const [stock, setStock] = React.useState();
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [product, setProduct] = React.useState({
        name: undefined,
        price: undefined,
        stock: undefined,
        image: undefined
    });
    React.useEffect(()=>{
        if (name === "" || name === undefined){
            setCanSave(false);
        } else {
            let tempError1 = String(name).length <= 1 || !isNaN(name);
            setError1(tempError1);
            (image === undefined || Number(price) === 0 || price === undefined || Number(stock) === 0 || stock === undefined)?setCanSave(false):setCanSave(true);
        }
    }, [name,image,stock,price])
    React.useEffect(()=>{
        if (image === "" || image === undefined){
            setCanSave(false);
        } else {
            let tempError2 = String(image).length <= 1 || !isNaN(image);
            setError2(tempError2);
            (name === undefined || Number(price) === 0 || price === undefined || Number(stock) === 0 || stock === undefined)?setCanSave(false):setCanSave(true);
        }
    }, [image,name,price,stock])
    React.useEffect(()=>{
        if (Number(price) === 0 || price === undefined){
            setCanSave(false);
        } else {
            let tempError3 = isNaN(price);
            setError3(tempError3);
            (image === undefined || name === undefined || Number(stock) === 0 || stock === undefined)?setCanSave(false):setCanSave(true);
        }
    }, [price,stock,image,name])
    React.useEffect(()=>{
        if (Number(stock) === 0 || stock === undefined){
            setCanSave(false);
        } else {
            let tempError4 = (isNaN(stock));
            setError4(tempError4);
            (image === undefined || name === undefined || Number(price) === 0 || price === undefined)?setCanSave(false):setCanSave(true);
        }
    }, [stock,price,name,image])

    React.useEffect(() => {
        let newElements = [];
        db.collection("products").get().then(function (snapshot){
            snapshot.forEach(function (doc){
                const object = doc.data();
                let element = {
                    name: object.name,
                    price: object.price,
                    stock: object.stock,
                    image: object.image,
                    id: doc.id
                };
                newElements.push(element);
            })
            setProducts(newElements);
            setCanSave(false);
            setProduct({
                name: undefined,
                price: undefined,
                stock: undefined,
                image: undefined
            });
        })
    }, [db, submit])

    const handleName = prop=>event=>{
        setName(event.target.value);
        setProduct({
            ...product, [prop]: event.target.value
        });
    };
    const handleImage = prop=>event=>{
        setImage(event.target.value);
        setProduct({
            ...product, [prop]: event.target.value
        });
    };
    const handlePrice = prop=>event=>{
        setPrice(event.target.value);
        setProduct({
            ...product, [prop]: event.target.value
        });
    };
    const handleStock = prop=>event=>{
        setStock(event.target.value);
        setProduct({
            ...product, [prop]: event.target.value
        });
    };
    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    const handleClickOpen = (id) => {
        setEditID(id)
        const editItem = products.filter(value => value.id === id);
        setName(editItem[0].name);
        setImage(editItem[0].image);
        setPrice(editItem[0].price);
        setStock(editItem[0].stock);
        setDialogOpen(true);
    };

    const handleClickClose = () => {
        setDialogOpen(false);
    };
    const handleSubmit = () => {
        setEdit(false);
        if (product.image===undefined || product.name===undefined || product.price===undefined || product.stock===undefined){
            setCanSave(false);
        }
        if((!error1) && (!error2) && (!error3) && (!error4) && canSave){
            db.collection("products").add(product).then(()=>{
                setProduct({
                    name: undefined,
                    price: undefined,
                    stock: undefined,
                    image: undefined
                });
                document.getElementById("inputForm").reset();
                setName(undefined);
                setImage(undefined);
                setPrice(undefined);
                setStock(undefined);
                setCanSave(false);
                setSubmit(!submit);
            })
            handleSnackClick();
        }
        setCanSave(false);
    };
    const handleDelete = (id)=>{
        db.collection("products").doc(id).delete().then(()=>{
            setSubmit(!submit);
        })
        setCanSave(false);
    };
    const handleEdit = ()=>{
        setEdit(true);
        if (product.image===undefined || product.name===undefined || product.price===undefined || product.stock===undefined){
            setCanSave(false);
        }
        if ((!error1) && (!error2) && (!error3) && (!error4) && canSave){
            db.collection("products").doc(editID).update({
                name: name,
                price: price,
                stock: stock,
                image: image
            }).then(()=>{
                setProduct({
                    name: undefined,
                    price: undefined,
                    stock: undefined,
                    image: undefined
                });
                document.getElementById("inputForm").reset();
                setName(undefined);
                setImage(undefined);
                setPrice(undefined);
                setStock(undefined);
                setSubmit(!submit);
            })
            setDialogOpen(false);
            handleSnackClick();
            setCanSave(false);
        }
        setCanSave(false);
    };
    const productEles = products.map((elt , idx)=>
        <div key={idx} style={{border:"solid 1px", maxWidth:"max-content", display: "inline-block"}}>
            <div style={{margin:"5px 10px 5px"}}>
                <h4>Product: {elt.name}</h4>
                <h4>Price: {elt.price}</h4>
                <h4>Stock: {elt.stock}</h4>
            </div>
            <div style={{display:"flex"}}>
                <Dialog
                    open={dialogOpen}
                    onClose={handleClickClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Edit Item"}</DialogTitle>
                    <DialogContent>
                        <form id="editForm" className={classes.root} noValidate autoComplete="off">
                            <div>
                                <TextField
                                    error={error1}
                                    onChange={handleName("name")}
                                    id={error1? "standard-error-helper-text":"textField1"}
                                    value={name}
                                    label="Name"
                                    helperText={error1? "Incorrect entry.":""}

                                />&nbsp;
                                <TextField
                                    error={error2}
                                    onChange={handleImage("image")}
                                    id={error2? "standard-error-helper-text":"textField2"}
                                    value={image}
                                    label="Image link"
                                    helperText={error2? "Incorrect entry.":""}

                                />&nbsp;
                                <TextField
                                    error={error3}
                                    onChange={handlePrice("price")}
                                    id={error3? "standard-error-helper-text":"textField3"}
                                    value={price}
                                    label="Price"
                                    helperText={error3? "Incorrect entry.":""}

                                />&nbsp;
                                <TextField
                                    error={error4}
                                    onChange={handleStock("stock")}
                                    id={error4? "standard-error-helper-text":"textField4"}
                                    value={stock}
                                    label="Stock"
                                    helperText={error4? "Incorrect entry.":""}

                                />
                            </div>
                        </form>
                        <Button onClick={handleEdit}>Submit</Button>
                    </DialogContent>
                </Dialog>
            </div>
            <button onClick={()=>handleClickOpen(elt.id)}>Edit</button>
            <button onClick={()=>handleDelete(elt.id)}>Delete</button>
        </div>
    );

    function handleSnackClick() {
        setOpen(true);
    }
    return(
        <div>
            <h1>Admin Page</h1>
            <div style={{marginLeft: "auto", marginRight: "auto", maxWidth:"1200px"}}>
                <p>Current Inventory</p>
                {productEles}
            </div>
            <p>Add Product</p>
            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    open={open}
                    autoHideDuration={2000}
                    onClose={handleSnackClose}
                    message={!error1 && !error2 && !error3 && !error4? edit?"Product successfully edited!":"Product successfully added!":"Failed. Please correct errors..."}
                />
            </div>
            <form id="inputForm" className={classes.root} noValidate autoComplete="off">
                <div>
                    <TextField
                        error={error1}
                        onChange={handleName("name")}
                        id={error1? "standard-error-helper-text":"textField1"}
                        label="Enter product name"
                        value={name}
                        helperText={error1? "Incorrect entry.":""}
                    />&nbsp;
                    <TextField
                        error={error2}
                        onChange={handleImage("image")}
                        id={error2? "standard-error-helper-text":"textField2"}
                        label="Enter image link"
                        value={image}
                        helperText={error2? "Incorrect entry.":""}
                    />&nbsp;
                    <TextField
                        error={error3}
                        onChange={handlePrice("price")}
                        id={error3? "standard-error-helper-text":"textField3"}
                        label="Enter price"
                        value={price}
                        helperText={error3? "Incorrect entry.":""}

                    />&nbsp;
                    <TextField
                        error={error4}
                        onChange={handleStock("stock")}
                        id={error4? "standard-error-helper-text":"textField4"}
                        label="Enter stock"
                        value={stock}
                        helperText={error4? "Incorrect entry.":""}
                    />
                </div>
            </form>
            <Button onClick={handleSubmit}>Submit</Button>
        </div>
    )
}
export default Admin;