import firebase from 'firebase';
const firebaseConfig = {
    apiKey: "AIzaSyAb8r4X8h4LRfyAegbKVgZfBZ11JseBkZI",
    authDomain: "novinyodemo.firebaseapp.com",
    databaseURL: "https://novinyodemo.firebaseio.com",
    projectId: "novinyodemo",
    storageBucket: "novinyodemo.appspot.com",
    messagingSenderId: "656776801483",
    appId: "1:656776801483:web:2646a9c31b494c2267ff4e",
    measurementId: "G-GHFD4DP07Q"
};

const fire=firebase.initializeApp(firebaseConfig);

export default fire;