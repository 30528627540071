import React from "react";
import {cart} from "../App";
import Snackbar from "@material-ui/core/Snackbar";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";

function Product(props){
    const [id] = React.useState(props.id);
    const [name] = React.useState(props.name);
    const [price] = React.useState(props.price);
    const [stock] = React.useState(props.stock);
    const [image] = React.useState(props.image);
    const [qty, setQty] = React.useState(1);
    const [open, setOpen] = React.useState(false);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    let style={
        minWidth:"150px",
        minHeight: "100px",
        height: "250px",
        width: "350px"
    }

    const handleClickOpen = () => {
        setDialogOpen(true);
    };

    const handleClickClose = () => {
        setDialogOpen(false);
    };

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    const handleQty = prop=>event=>{
        setQty(event.target.value);
    };
    const addToCart = ()=>{
        let found = false;
        cart.forEach(value => {
            if (value.desc === name){
                value.qty = Number(value.qty) + Number(qty);
                found = true;
            }
        })
        if (!found){
            cart.push({
                desc: name,
                qty: qty,
                unit: price,
                stock: stock,
                id: id
            });
        }
        setOpen(true);
    }
    return(
        <div style={{display:"inline-block", maxWidth:"1050px"}}>
            <div style={style} onClick={handleClickOpen}>
                <img src={image} height={250} width={300} alt={"product"}/>
                <h1>{name}</h1>
                <h2>Price: ${price}</h2>
                <h3>Stock: {stock}{Number(stock)===0?<span style={{color:"red"}}>&nbsp;(Out of Stock)</span>:Number(stock)<=10?<span style={{color:"red"}}>&nbsp;(Low Stock!!!)</span>:""}</h3>
            </div>

            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                open={open}
                autoHideDuration={4000}
                onClose={handleSnackClose}
                message="Added to Cart!"
            />
            <div style={{display:"flex"}}>
                <Dialog
                    open={dialogOpen}
                    onClose={handleClickClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Item Details"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <img src={image} height={250} width={300} alt={"product"}/>
                            <h1>{name}</h1>
                            <h2>Price: ${price}</h2>
                            <h3>Stock: {stock}{Number(stock)===0?<span style={{color:"red"}}>&nbsp;(Out of Stock)</span>:Number(stock)<=10?<span style={{color:"red"}}>&nbsp;(Low Stock!!!)</span>:""}</h3>
                        </DialogContentText>
                        <label htmlFor="quantity">Qty:</label>
                        <input type="number" value={qty} id="qty" name="quantity" min={1} max={Number(stock)} onChange={handleQty("qty")} style={{width:"40px"}}/>
                        <button disabled={Number(stock) === 0} onClick={()=>addToCart()}>Add to Cart</button>
                    </DialogContent>
                    {cart.length !== 0 ? <Link to={"/cart"}><Button size="small" color="secondary">Proceed to checkout ({cart.length} item(s))</Button></Link>:""}
                </Dialog>
            </div>
        </div>
    )
}
export default Product;